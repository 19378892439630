<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4">
    <div v-for="date in sortedItemKeys" :key="date" class="bg-white rounded-lg px-2 pb-3 pt-1.5 flex flex-col gap-3">
      <div class="font-semibold text-md">
        <div v-if="isToday(date)">
          {{ $t('today') }}
          <div class="font-semibold text-xs text-gray-500">
            {{ date | formatDate }}
          </div>
        </div>
        <div v-else-if="isYesterday(date)">
          {{ $t('yesterday') }}
          <div class="font-semibold text-xs text-gray-500">
            {{ date | formatDate }}
          </div>
        </div>
        <div v-else>
          {{ date | formatDate }}
          <div class="font-semibold text-xs text-gray-500">
            {{ new Date(date).toLocaleDateString('pt-PT', { weekday: 'long' }) }}
          </div>
        </div>
      </div>

      <RecordAttendance
        v-for="(item, index) in items[date]"
        :key="index"
        :item="item"
        @toggle="$emit('toggle', $event)"
      ></RecordAttendance>
    </div>
  </div>
</template>

<script>
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import RecordAttendance from '@/components/records/RecordAttendance'

export default {
  components: {
    RecordAttendance,
  },
  props: {
    items: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    sortedItemKeys() {
      if (this.items)
        return Object.keys(this.items)
          .sort()
          .reverse()

      return []
    },
  },

  methods: {
    isToday(date) {
      return Dates.isToday(date)
    },
    isYesterday(date) {
      return Dates.isYesterday(date)
    },
    getWeekDay(date) {
      return Dates.buildCore(date).day()
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "P10": "Entrada",
    "P20": "Saída",
    "today": "Hoje",
    "yesterday": "Ontem",
    "justification": "Justificação",
    "weekdays": {
      "0": "Domingo",
      "1": "Segunda-feira",
      "2": "Terça-feira",
      "3": "Quarta-feira",
      "4": "Quinta-feira",
      "5": "Sexta-feira",
      "6": "Sábado"
    }

  },
  "en": {
    "P10": "Enter",
    "P20": "Exit",
    "today": "Today",
    "yesterday": "Yesterday",
    "justification": "Justification",
    "weekdays": {
      "0": "Sunday",
      "1": "Monday",
      "2": "Tuesday",
      "3": "Wednesday",
      "4": "Thursday",
      "5": "Friday",
      "6": "Saturday"
    }
  }
}
</i18n>
