<template>
  <fw-layout mobile-ready wide>
    <template #main-content>
      <fw-panel :title="$t('attendances')" featured :loading="loading.attendances">
        <LoadingPlaceholder v-if="loading.attendances" size="sm" />

        <div
          v-else-if="stats?.timetableType"
          class="flex flex-col md:flex-row gap-5 md:justify-between md:items-center mb-5 pb-5 border-b border-gray-200"
        >
          <div class="flex gap-10">
            <div>
              <div>
                <fw-label>Saldo atual</fw-label>
              </div>
              <div>
                <fw-tag
                  size="lg"
                  custom-class="px-4 flex gap-3 items-center"
                  :type="
                    !stats.currentBalance
                      ? 'medium'
                      : stats.currentBalance.substring(0, 1) === '-'
                      ? 'danger'
                      : 'primary'
                  "
                >
                  <div>{{ stats.currentBalance || '-:--' }} <span class="font-normal opacity-70 ml-0.5">h</span></div>
                  <div v-if="stats.currentBalanceDate" class="text-xs opacity-70 ml-1.5">
                    em {{ stats.currentBalanceDate }}
                  </div>
                </fw-tag>
              </div>
            </div>
            <div>
              <div>
                <fw-label>Saldo do mês anterior</fw-label>
              </div>
              <div>
                <fw-tag
                  size="lg"
                  custom-class="px-4 flex gap-3 items-center"
                  :type="
                    !stats.previousMonthBalance
                      ? 'light'
                      : stats.previousMonthBalance.substring(0, 1) === '-'
                      ? 'danger'
                      : 'primary'
                  "
                >
                  <div>
                    {{ stats.previousMonthBalance || '-:--' }} <span class="font-normal opacity-70 ml-0.5">h</span>
                  </div>
                  <div v-if="stats.previousMonthBalanceDate" class="text-xs opacity-70 ml-1.5">
                    em {{ stats.previousMonthBalanceDate }}
                  </div>
                </fw-tag>
              </div>
            </div>
          </div>
          <div v-if="stats?.timetableType">
            <div>
              <fw-label>Tipo de horário</fw-label>
            </div>
            <div class="font-medium text-gray-500">{{ stats.timetableType }}</div>
          </div>
        </div>
        <fw-panel
          v-if="balances && Object.keys(balances).length"
          :title="$t('balancesPerMonth')"
          :loading="loading.balances"
          boxed="sm"
          class="my-10"
        >
          <template #toolbar>
            <b-dropdown v-if="$device.isWTablet()" aria-role="list" position="is-bottom-left" class="paddingless mr-2">
              <fw-button-dropdown
                slot="trigger"
                type="transparent"
                class="flex flex-col text-gray-500 font-semibold"
                :label="$t('export')"
              />
              <b-dropdown-item aria-role="menuitem" paddingless>
                <fw-button type="dropdown-item" :disabled="loading.exportToCSV" @click.native="exportBalancesResults()">
                  {{ $t('exportCSV') }}
                </fw-button>
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown v-if="$device.isWDesktop()" aria-role="list" position="is-bottom-left">
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                type="xlight"
                :label="balanceOptions[selectedOption].label"
                class="font-medium text-gray-500"
              >
              </fw-button-dropdown>
              <b-dropdown-item
                v-for="(value, key) in balanceOptions"
                :key="key"
                :label="value.label"
                aria-role="listitem"
                :class="`font-medium ${key == selectedOption ? 'text-primary' : 'text-gray-500'}`"
                @click.native="selectOption(key)"
              >
                {{ value.label }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <LoadingPlaceholder v-if="loading.balances" />
          <div v-else>
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-10">
              <div class="flex flex-col gap-3">
                <fw-heading>{{ year }}</fw-heading>
                <fw-panel-info
                  v-if="!balances?.[year]"
                  centered
                  full
                  empty
                  custom-class="flex justify-center items-center min-h-40"
                >
                  Sem informação de saldos para apresentar.
                </fw-panel-info>
                <div v-else-if="$device.isWDesktop() && selectedOption == 'graph'" class="relative">
                  <ChartBar :chart-data="chartData[`${year}`]" class="h-72" :options="options"></ChartBar>
                </div>
                <div v-else>
                  <RecordWorkerBalance
                    v-for="(value, key, index) in balances[year]"
                    :key="`${year}-${key}-${index}`"
                    :year="year"
                    :month="key"
                    :item="value"
                  ></RecordWorkerBalance>
                </div>
              </div>
              <div class="flex flex-col gap-3">
                <fw-heading muted>{{ year - 1 }}</fw-heading>
                <fw-panel-info
                  v-if="!balances?.[year - 1]"
                  centered
                  empty
                  class="h-full"
                  custom-class="flex justify-center items-center min-h-40"
                >
                  Sem informação de saldos para apresentar.
                </fw-panel-info>
                <div v-else-if="$device.isWDesktop() && selectedOption == 'graph'" class="relative">
                  <ChartBar :chart-data="chartData[`${year - 1}`]" class="h-72" :options="options"></ChartBar>
                </div>
                <div v-else>
                  <RecordWorkerBalance
                    v-for="(value, key, index) in balances[year - 1]"
                    :key="`${year}-${key}-${index}`"
                    :year="year"
                    :month="key"
                    :item="value"
                  ></RecordWorkerBalance>
                </div>
              </div>
            </div>
          </div>
        </fw-panel>

        <fw-panel title="Marcações por dia" :loading="loading.attendances" class="my-10">
          <template v-if="!loading.attendances && attendances && Object.keys(attendances).length" #toolbar>
            <b-dropdown v-if="$device.isWTablet()" aria-role="list" position="is-bottom-left" class="paddingless mr-2">
              <fw-button-dropdown
                v-if="attendances && Object.keys(attendances).length"
                slot="trigger"
                type="transparent"
                class="flex flex-col text-gray-500 font-semibold"
                :label="$t('export')"
              />
              <b-dropdown-item paddingless aria-role="menuitem">
                <fw-button
                  type="dropdown-item"
                  :disabled="loading.exportToCSV"
                  @click.native="exportAttendancesResults"
                >
                  {{ $t('exportCSV') }}
                </fw-button>
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown aria-role="list" position="is-bottom-left">
              <fw-button-dropdown
                slot="trigger"
                aria-role="listitem"
                type="xlight"
                :label="ranges[selectedRange].label"
                class="font-medium text-gray-500"
              >
              </fw-button-dropdown>
              <b-dropdown-item
                v-for="(value, key) in ranges"
                :key="key"
                :label="value.label"
                aria-role="listitem"
                :class="`font-medium ${key == selectedRange ? 'text-primary' : 'text-gray-500'}`"
                @click.native="selectRange(key)"
              >
                {{ value.label }}
              </b-dropdown-item>
            </b-dropdown>
          </template>

          <LoadingPlaceholder v-if="loading.attendances" :list="false" />
          <div v-else>
            <AttendancesList
              v-if="attendances && Object.keys(attendances).length > 0"
              :items="attendances"
              @toggle="confirmUpdateAttendances"
            />
            <fw-panel-info v-else centered empty>
              Não existem marcações de assiduidade para apresentar.
            </fw-panel-info>
          </div>
        </fw-panel>
      </fw-panel>
      <fw-panel-info debug label="Data (raw)" class="my-3">
        <json-viewer :value="{ stats, balances, attendances }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>

<script>
import ChartBar from '@/fw-modules/fw-core-vue/ui/components/charts/ChartBar'
import AttendancesList from '@/components/panels/AttendancesList'
import RecordWorkerBalance from '@/components/records/RecordWorkerBalance'
import ServiceSettings from '@/fw-modules/fw-core-vue/id/services/ServiceSettings'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'
import LoadingPlaceholder from '@/fw-modules/fw-core-vue/ui/components/animation/LoadingPlaceholder'
import { Chart as ChartJS } from 'chart.js'

export default {
  components: {
    AttendancesList,
    RecordWorkerBalance,
    LoadingPlaceholder,
    ChartBar,
  },

  data() {
    return {
      loading: {
        balances: true,
        attendances: true,
        exportToCSV: false,
      },
      stats: {
        timetableType: null,
        currentBalance: null,
        previousMonthBalance: null,
      },
      today: Dates.now(),
      year: null,
      month: null,

      balanceOptions: {
        graph: {
          label: this.$t('seeGraph'),
        },
        list: {
          label: this.$t('seeList'),
        },
      },

      selectedOption: 'graph',
      balances: {},
      chartData: {},
      options: {
        responsive: true,
        maintainAspectRatio: false,

        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
          },
        },

        events: [],

        legend: {
          display: false,
        },

        scales: {
          xAxes: [
            {
              ticks: {
                fontColor: '#999999',
                fontFamily: "'Inter', 'Roboto', 'system-ui', 'sans-serif'",
              },
              barPercentage: 0.3,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                display: false,
                max: 10.0,
                min: -5.0,
              },
              gridLines: {
                color: 'transparent',
                display: true,
                drawBorder: false,
                zeroLineColor: '#999999',
                zeroLineWidth: 1,
              },
            },
          ],
        },

        animation: {
          duration: 0,
          onComplete: x => {
            const chart = x.chart
            let { ctx } = chart
            ctx.textAlign = 'center'
            ctx.fillStyle = '#777777'
            ctx.textBaseline = 'top'
            ctx.font = ChartJS.helpers.fontString(
              ChartJS.defaults.global.defaultFontSize,
              'bold',
              'Inter, Roboto, system-ui, sans-serif'
            )

            chart.data.datasets.forEach(function(dataset, i) {
              let meta = chart.controller.getDatasetMeta(i)
              meta.data.forEach(function(bar, index) {
                if (dataset.data[index] < 0) {
                  ctx.fillStyle = '#EF6161'
                } else {
                  ctx.fillStyle = '#777777'
                }
                const data = dataset.data[index].toFixed(2).replace('.', ':')
                ctx.fillText(data, bar._model.x, 10)
              })
            })
          },
        },
      },

      selectedRange: 'last7Days',
      attendances: {},
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    },

    ranges() {
      return {
        last7Days: {
          label: this.$t('lastDays', { days: 7 }),
          startDate: this.today.subtract(7, 'days').format('YYYY-MM-DD'),
        },
        last30Days: {
          label: this.$t('lastDays', { days: 30 }),
          startDate: this.today.subtract(30, 'days').format('YYYY-MM-DD'),
        },
      }
    },
  },

  created() {
    this.month = this.today.month()
    this.year = this.today.year()
    ServiceSettings.checkAppVersion()
  },

  mounted() {
    this.getUserAttendances()
    this.getUserBalances()
  },

  methods: {
    async getUserAttendances() {
      this.loading.attendances = true

      try {
        const response = await this.api.getUserAttendances({
          start_at: this.ranges[this.selectedRange].startDate,
          end_at: this.today.format('YYYY-MM-DD'),
          balance_info: true,
          group_by_date: true,
        })
        console.log('getUserAttendances :>> ', response)

        this.attendances = response.attendances

        this.stats['timetableType'] = response.schedule
        this.stats['previousMonthBalance'] = response.balance_last_month
        this.stats['currentBalance'] = response.balance
        this.stats['currentBalanceDate'] = response.balance_date
          ? this.$options.filters.formatDate(response.balance_date)
          : null
        this.stats['previousMonthBalanceDate'] = response.balance_date_last_month
          ? this.$options.filters.formatDate(response.balance_date_last_month)
          : null

        console.log('this.attendances :>> ', this.attendances)
      } catch (e) {
        console.error('Error getUserAttendances :>>', e)
        const error = utils.errors(e).get()
        this.$buefy.toast.open({
          message: error.detail,
          type: 'is-danger',
          duration: 4000,
        })
      }

      setTimeout(() => {
        this.loading.attendances = false
      }, 500)
    },

    async getUserBalances() {
      this.loading.balances = true

      try {
        const response = await this.api.getUserBalances({
          start_at: this.today
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          end_at: this.today.endOf('year').format('YYYY-MM-DD'),
        })

        console.log('getUserBalances :>> ', response)
        this.balances = response.records

        const tmpbalances = {}
        tmpbalances[this.year] = []
        tmpbalances[this.year - 1] = []

        const barBackgroundColors = {}
        barBackgroundColors[this.year] = []
        barBackgroundColors[this.year - 1] = []

        if (!utils.isObjectEmpty(response.records)) {
          for (let i = 1; i < 13; i++) {
            if (response?.records?.[this.year]) {
              const currYearVal = response.records[this.year][i]
                ? parseFloat(response.records[this.year][i].balance.replace(/\s/g, '').replace(':', '.')) || 0
                : 0
              tmpbalances[this.year].push(currYearVal)
              barBackgroundColors[this.year].push(currYearVal < 0 ? '#EF6161' : '#CDCDCD')
            }

            if (response?.records?.[this.year - 1]) {
              const lastYearVal = response.records[this.year - 1][i]
                ? parseFloat(response.records[this.year - 1][i].balance.replace(/\s/g, '').replace(':', '.')) || 0
                : 0
              tmpbalances[this.year - 1].push(lastYearVal)
              barBackgroundColors[this.year - 1].push(lastYearVal < 0 ? '#EF6161' : '#CDCDCD')
            }
          }
        }

        console.log('tmpbalances :>> ', tmpbalances)

        this.chartData[this.year] = {
          labels: Object.values(this.$t('monthsAbbreviations')),
          datasets: [
            {
              label: `Balance`,
              backgroundColor: barBackgroundColors[this.year],
              data: tmpbalances[this.year],
            },
          ],
        }
        this.chartData[this.year - 1] = {
          labels: Object.values(this.$t('monthsAbbreviations')),
          datasets: [
            {
              label: `Balance`,
              backgroundColor: barBackgroundColors[this.year - 1],
              data: tmpbalances[this.year - 1],
            },
          ],
        }
      } catch (e) {
        console.error('Error getUserBalances :>>', e)

        const error = utils.errors(e).get()
        this.$buefy.toast.open({
          message: error.detail,
          type: 'is-danger',
          duration: 4000,
        })
      }

      this.loading.balances = false
    },

    selectRange(rangeKey) {
      console.log('selectRange :>> ', rangeKey)
      this.selectedRange = rangeKey
      this.getUserAttendances()
    },

    async exportBalancesResults() {
      this.loading.exportToCSV = true
      try {
        const data = await this.api.getUserBalances({
          start_at: this.today
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          end_at: this.today.format('YYYY-MM-DD'),
          format: 'csv',
        })
        const url = window.URL.createObjectURL(new Blob([data]))
        const now = Dates.now().format('YYYY-MM-DD_HH:mm:ss')
        utils.downloadFile(url, `${this.$t('balances')}_${now}.csv`)
        await utils.sleep(500)
      } catch (e) {
        const errorKey = utils.errors(e).getKey()
        console.error(`Error exportBalancesResults (${errorKey}):>>`, e)
        this.$buefy.dialog.alert({
          title: this.$t('error'),
          message: this.$t('errorInfoCSV', { key: errorKey ? `(${errorKey})` : '' }),
          type: 'is-danger',
        })
      }

      this.loading.exportToCSV = false
    },

    async exportAttendancesResults() {
      this.loading.exportToCSV = true
      console.log('this.selectedRange', this.selectedRange)
      try {
        const data = await this.api.getUserAttendances({
          year: this.year,
          start_at: this.ranges[this.selectedRange].startDate,
          end_at: this.today.format('YYYY-MM-DD'),
          format: 'csv',
        })
        const url = window.URL.createObjectURL(new Blob([data]))
        const now = Dates.now().format('YYYY-MM-DD_HH:mm:ss')
        utils.downloadFile(url, `${this.$t('attendances')}_${now}.csv`)
        await utils.sleep(500)
      } catch (e) {
        const errorKey = utils.errors(e).getKey()
        console.error(`Error exportAttendancesResults (${errorKey}):>>`, e)
        this.$buefy.dialog.alert({
          title: this.$t('error'),
          message: this.$t('errorInfoCSV', { key: errorKey ? `(${errorKey})` : '' }),
          type: 'is-danger',
        })
      }

      this.loading.exportToCSV = false
    },

    selectOption(key) {
      this.selectedOption = key
    },

    confirmUpdateAttendances(item) {
      this.$buefy.dialog.confirm({
        type: 'is-primary',
        title: `Editar marcação`,
        message: `A marcação vai ser alterada de <b>${item.action === 'entry' ? 'Entrada' : 'Saída'}</b> para <b>${
          item.action === 'entry' ? 'Saída' : 'Entrada'
        }</b>. Tem a certeza que deseja continuar?`,
        onConfirm: () => {
          this.toggleAttendance(item)
        },
        confirmText: 'Alterar',
        cancelText: 'Cancelar',
      })
    },

    async toggleAttendance(item) {
      await utils.tryAndCatch(this, async () => {
        const response = await this.api.toggleUserAttendances({
          date: item.date,
          time: item.time,
          action: item.action,
        })
        if (response) this.getUserAttendances()
      })
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "attendances": "Marcações",
    "timetableType": "Tipo de horário",
    "currentBalance": "Saldo atual",
    "balances": "Saldos",
    "previousMonthBalance": "Saldo mês anterior",
    "balancesPerMonth": "Saldos por mês",
    "attendancesList": "Lista de marcações",
    "noAttendances": "Sem marcações",
    "noBalances": "Sem saldos",
    "export": "Exportar",
    "exportCSV": "Exportar para CSV",
    "seeList": "Ver em lista",
    "seeGraph": "Ver em gráfico",
    "lastDays": "Últimos {days} dias",
    "error": "Ocorreu um erro",
    "errorInfoCSV": "Ocorreu um erro não esperado{key} a exportar o CSV. Por favor, contacte a nossa equipa de suporte.",
    "monthsAbbreviations": {
      "1": "Jan",
      "2": "Fev",
      "3": "Mar",
      "4": "Abr",
      "5": "Mai",
      "6": "Jun",
      "7": "Jul",
      "8": "Ago",
      "9": "Set",
      "10":"Out",
      "11":"Nov",
      "12":"Dez"
    }
  },
  "en": {
    "attendances": "Marcações",
    "timetableType": "Type of timetable",
    "currentBalance": "Current balance",
    "balances": "Balances",
    "previousMonthBalance": "Previous month balance",
    "attendancesList": "Attendances list",
    "noAttendances": "No attendances",
    "noBalances": "No balances",
    "export": "Export",
    "exportCSV": "Export to CSV",
    "seeList": "View in list",
    "seeGraph": "View in graphic",
    "lastDays": "Last {days} days",
    "error": "Ocorreu um erro",
    "errorInfoCSV": "An unexpected error occurred{key} exporting to CSV. Please contact our support team.",
    "monthsAbbreviations": {
      "1": "Jan",
      "2": "Feb",
      "3": "Mar",
      "4": "Apr",
      "5": "May",
      "6": "June",
      "7": "July",
      "8": "Aug",
      "9": "Sept",
      "10":"Oct",
      "11":"Nov",
      "12":"Dec"
    }
  }

}
</i18n>
